.challenge-container {
  width: 475px;
  /* background-color: #ffffff; */
  height: 547px;
  position: fixed;
  right: 5%;
  top: 20%;
  z-index: 100;
  /* border-radius: 22px; */
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  color: #333 ;
}

pre {
  background: #eeeeee;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
}

.challenge-footer {
  color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 600;
}


#challenge-top {

}

.minimize-button {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: white;
  padding: 4px;
  border-radius: 50%;
  transition: background-color 0.3s;
}

.minimize-button:hover {
  background-color: #E3791C;
}

.challenge-nav-container {
  display: flex;
  gap: 10px;
}


.challenge-nav-container button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}



.minimized-popup {
  position: fixed;
  bottom: 10px;
  right: 50px;
  /* background-color: chocolate; */
  background-color: var(--color-text1);
  color: white;
  border: none;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background-color 0.3s;
}

.minimized-popup:hover {
  background-color: var(--color-text1hover);
}
