
.tab-container {
  /* padding: 5px 5px 0 5px; */
  margin: 0;
}

/* .padding-for-challenge {
  padding-left: 500px;
} */


.tabs {
  display: flex;
}

.tab-button {
  color: #333;
  padding: 4px 15px 6px 10px;
  cursor: pointer;
  /* border-left: 1px solid #DDDBDA; */
  display: flex;
  align-items: center;
  justify-content: center;

}

.tab-button-text {
  display: inline-block;
  margin-top: 3px;
}

button.active {
  background-color: white;
}

.tab-content {
  /* margin: 0 5px; */
  /* height: 55%; */

}





.close-tab-btn {
  /* visibility: hidden; */
  position: relative;
  right: -10px;
  border: 1px solid gray;
  background-color: var(--color-light-bg1);
  color: #555;
  padding: 1px 5px 2px 5px;
  cursor: pointer;
  font-size: 10px;


  display: flex;
  align-items: center;
  justify-content: center;


}

.generic-sidepane-container {
  background-color: white;
  /* background-color: var(--color-light-bg1); */
  /* position: absolute; */
  border-right: #F3F2F2 1px solid;
  overflow: auto;
}


/* for tailwindcss */
.sidepane-button {
  @apply hover:bg-gray-100 cursor-pointer hover:border border-gray-200 flex flex-col items-center w-[40px] h-[40px] rounded-xl justify-center stroke-gray-700;
}

.sidepane-button svg {
  width: 25px;
  height: 25px;
}


.sidepane-button-active {
  @apply text-white bg-text1 stroke-white hover:border-none hover:bg-text1hover;
}

.sidepane-button-disabled {
  @apply cursor-default ;
}

