.query-container {
    /* background-color: var(--color-light-bg1); */
    /* border: 1px solid var(--color-dark1); */
    height: 100%;
    display: flex;
    flex-direction: row;
}

.editor-and-results {
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;
    /* height: 100%; */
    /* margin-top: 10px; */
}

.query-tabs-container {
    background-color: white;
    /* border-top: 5px solid var(--color-green1); */
    display: flex;
    flex-direction: row;
    height: 100vh;
    /* height: calc(100vh - 100px); */

}


.tab-container {
    height: 100%;
    /* width: 100%; */
    /* background-color: antiquewhite; */
    display: flex;
    flex-direction: column;
}

.no-user-select {
    outline-color: transparent;
}

.visualization-options {
    display: inline-block;
    
}


.results-view {
    /* height: 49%; */
    /* flex: 1; */

    /* margin-top: 10px; */
    border: 1px solid gray;
}

/* .ag-charts-canvas-center{
    height: 100% !important ;
    width: 100% !important ;
} */
/* .ag-charts-canvas{
    height: 400px !important; 
} */

/* 
.ag-charts-wrapper {
    height: 100% !important;
}


.ag-charts-canvas-center canvas{
    height: 100% !important;
} */