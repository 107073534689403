#sidebar {
  padding: 23px 21px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100vh;
  width: var(--sidebar-width);
  /* position: fixed; */
  /* min-height: 100vh; */
  background-color: #26292D;
  padding-top: 20px;
}

.sidebar-link a {
  color: white;

}

.sidebar-link {
  margin-top: 10px;
  margin-bottom: 10px;
}

.sidebar-link-text {
  font-size: 16px;
  font-family: 'Roboto';
  font-weight: regular;
}

.disabled-link {
  color: #aaa;
}

#profile {
  display: flex;
  flex-direction: row;
}




.user-icon {
  width: 20px;
  height: 20px;
  padding: 5px;
  border-radius: 50%;
  /* background-color: chocolate; */
  background-color: var(--color-text1);
  color: white;
  justify-content: center;
}

#user-display-text {
  margin-left: 10px;
  margin-top: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}