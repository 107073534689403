@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --color-light-bg1: #F9F9F9;
    /* --color-light-bg1: #F0F3F7; */
    --color-dark1: #1C4D53;
    --color-dark2: #0C1E28;
    --color-green1: #02845E;
    --color-text1_backup: #FE7A30;  /* main orange */
    --color-text1: #3C4B9C;  /* main orange */
    --color-text1hover: black;  /* main orange */

    --header-height: 100px;
    
    --border-radius: 4px;

    --font-sans: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial,
      sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    --font-mono: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
      "Courier New", monospace;
    --font-lg: 18px;
    --font-md: 16px;
    --font-sm: 14px;
    --font-sx: 12px;
    --line-height-loose: 1.75;
    --line-height-normal: 1.5;
    --line-height-dense: 1.1;
    --space-1: 4px;
    --space-2: 8px;
    --space-3: 16px;
    --space-4: 24px;
    --space-5: 32px;
    --space-6: 64px;
    --size-content: 100%;
    --size-gutter: 0;
    --size-gap: var(--space-6);
  }
  
  body {
    -webkit-text-size-adjust: 100%;
    box-sizing: border-box;
    font-size: var(--font-md);
    line-height: var(--line-height-normal);
    overflow-y: hidden;
    min-width: 1000px;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: 'Roboto', sans-serif;
    font-weight: normal;
    margin: 0;
    word-wrap: break-word;
    height: 100vh;
  }

  #app-container{
    
    overflow: hidden;
  }

  a {
    color: var(--color-dark1);
    text-decoration: none;
    font-weight: 500;
  }
  a:active,
  a:hover {
    text-decoration: none;
    color: black;
  }
  abbr[title] {
    border-bottom: 1px dotted hsla(0, 0%, 0%, 0.5);
    cursor: help;
    text-decoration: none;
  }
  b,
  strong {
    font-weight: inherit;
    font-weight: bolder;
  }
  dfn {
    font-style: italic;
  }
  h1 {
    margin: 0;
    margin-bottom: 3rem;
    padding: 0;
    line-height: var(--line-height-dense);
    letter-spacing: -0.01em;
  }
  h1 > b {
    color: var(--color-dark1);
  }
  img {
    border-style: none;
    max-width: 100%;
  }
  code,
  kbd,
  pre,
  samp {
    font-family: var(--font-mono);
    font-size: 1em;
    line-height: inherit;
  }
  hr {
    box-sizing: content-box;
    overflow: visible;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding: 0;
    margin-bottom: calc(var(--space-4) - 1px);
    background: hsla(0, 0%, 0%, 0.2);
    border: none;
    height: 1px;
  }
  * {
    box-sizing: inherit;
  }
  *:before {
    box-sizing: inherit;
  }
  *:after {
    box-sizing: inherit;
  }
  
  /* ul,
  ol {
    margin-left: var(--space-4);
    list-style-position: outside;
    list-style-image: none;
  } */
  pre {
  
    font-size: 0.875rem;
    line-height: var(--line-height-normal);
    /* background: hsla(0, 0%, 0%, 0.04); */
    background: var(--color-light-bg1);
    border-radius: var(--border-radius);
    overflow: auto;
    word-wrap: normal;
    /* max-width: 80%; */
    padding: var(--space-2);
  }
  b,
  strong,
  dt,
  th {
    font-weight: bold;
  }
  /* li {
    margin-bottom: calc(var(--space-4) / 2);
  }
  ol li,
  ul li {
    padding-left: 0;
  }
  li > ol,
  li > ul {
    margin-bottom: calc(var(--space-4) / 2);
    margin-left: var(--space-4);
    margin-top: calc(var(--space-4) / 2);
  } */
  blockquote *:last-child {
    margin-bottom: 0;
  }
  /* li *:last-child {
    margin-bottom: 0;
  } */
  p *:last-child {
    margin-bottom: 0;
  }
  /* li > p {
    margin-bottom: calc(var(--space-4) / 2);
  } */
  p {
    max-width: 680px;
  }
  code {
    font-size: 0.875rem;
  }
  kbd {
    font-size: 0.875rem;
  }
  samp {
    font-size: 0.875rem;
  }
  abbr {
    border-bottom: 1px dotted hsla(0, 0%, 0%, 0.5);
    cursor: help;
  }
  acronym {
    border-bottom: 1px dotted hsla(0, 0%, 0%, 0.5);
    cursor: help;
  }
  
  tt,
  code {
    background-color: var(--color-light-bg1);
    border-radius: var(--border-radius);
    color: var(--color-dark1);
    font-family: var(--font-mono);
    padding-bottom: 0.25em;
    padding-top: 0.25em;
    word-break: normal;
  }
  pre code {
    background: none;
  }
  
  code:before,
  code:after,
  tt:before,
  tt:after {
    content: "\00a0";
    letter-spacing: -0.2em;
  }
  pre code:before,
  pre code:after,
  pre tt:before,
  pre tt:after {
    content: none;
  }
  
  ul {
    margin-left: 0 !important;
    /* padding-left: 25px; */
  }
  
  li {
    margin-left: 0 !important;
  
  }
  
  /* action-button */
  .action-button-dark {
    background-color: var(--color-dark2);
    color: white;
    font-size: 18px;
    font-weight: 500;
    padding: 15px;
    border-radius: 6px;
    border: 0;
  }
  
  .action-button-light {
    background-color: var(--color-green1);
    color: white;
    font-size: 18px;
    font-weight: 500;
    padding: 15px;
    border-radius: 6px;
    border: 0;
  }
  
  .action-button-light:disabled {
    background-color: grey;
  }
  .action-button-dark:disabled {
    background-color: grey;
  
  }
  
  .action-button-small {
    @apply bg-text1 text-white h-[40px] px-[25px] rounded-3xl cursor-pointer;
  }
  .action-button-small:disabled {
    background-color: grey;
  }
  .action-button-small:hover {
    background-color: var(--color-text1hover);
    /* background-color: #E3791C; */
  }
  
  
  .action-button-dark a { 
    color: white ;
    text-decoration: none;
  }
  
  .action-button-light a { 
    color: white;
    text-decoration: none;
  }
  
  /* function-button */
  .function-button-light {
    background-color: var(--color-green1);
    color: white;
    font-size: 16px;
    font-weight: 500;
    padding: 10px;
    border-radius: 1px;
    border: 1px solid black;
  }
  
  .function-button-light:disabled {
    background-color: grey;
  }
  
  /* post */
  .post {
    background-color: var(--color-dark1);
    color:white;
    padding: 40px;
    min-height: calc(100vh - 100px);
  }
  
  .post-content {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    /* font-size: 18px;
    line-height: 1.5; */
  }
  
  .post-content a {
    color: var(--color-text1);
    text-decoration: none;
  }

  .full-height {
    height: 100%;
  }
  
  
  
  
  

  #profile-menu-container {
    position: absolute;
    bottom: 16px;
    left: 170px;
    background-color: transparent;
    padding-left: 30px;
    border-radius: 4px;
    flex-direction: row;
  }
  
  
  .tooltip-container {
    position: relative;
    /* display: inline-block; */
    /* cursor: pointer; */
  }
  
  .tooltip {
    display: inline;
    position: absolute;
    bottom: 100%; /* Position above the container */
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 8px; /* Space between tooltip and container */
    padding: 8px;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 4px;
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;  
    transition: opacity 0.3s ease;
    z-index: 9999;
  }

  .shadow-left {
    box-shadow: -4px 0 10px rgba(0, 0, 0, 0.25); 

    /* box-shadow:rgba(0, 0, 0, 0.08) -5px 0px 20px 0px, rgba(0, 0, 0, 0.08) -6px 0px 10px 0px */
  }

  .tooltip-bottom {
    top: 100%; /* Position above the container */
    background-color: var(--color-dark1);
    
  }
  .tooltip-right {
    left: 110%; /* Position above the container */
    transform: translateX(0);
    top: -15px;
    
  }

  .tooltip-bottom-left {
    top: 100%; /* Position above the container */
    left:0;
    background-color: var(--color-dark1);
    
  }
  
  .tooltip svg {
    display: inline;
  }
  
  .tooltip-container:hover .tooltip {
    opacity: 1; /* Show tooltip */
    visibility: visible; /* Show tooltip */
  }
  
  .fast-transition {
    transition: all 0.1s ease-in-out; 
  }
   
    .spinner {
      display: inline-block;
      width: 15px;
      height: 15px;
      
      border: 3px solid #f3f3f3;
      border-radius: 50%;
      border-top: 3px solid #fff;
      animation: spin 1s linear infinite;
    }
 
.spinner2 {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: var(--color-text1);
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin2 1s linear infinite;
}

@keyframes spin2 {
  to {
    transform: rotate(360deg);
  }
} 

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
